import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { BASE_URL } from '../config';

const Weather = () => {
    const [city, setCity] = useState('delhi');
    const [cityName, setCityName] = useState('');
    const [coordinates, setCoordinates] = useState({ lat: 0, lon: 0 });
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [dateTime, setDateTime] = useState('')
    const apiKey = process.env.REACT_APP_API_KEY; 

    useEffect(() => { 
        const timerId = setTimeout(() => {
           city &&  getCity()
        }, 1500);
        return () => {
            clearTimeout(timerId);
        };
    }, [city])
    useEffect(() => {

        getWeatherData()
    }, [coordinates])
    useEffect(() => { 
        getTime()
    }, [])
    const getTime = () => {
        const currentDate = new Date();
        const date = moment(currentDate).format('MMMM Do YYYY');
        setDateTime(date)

    }
    const getCity = async () => {
        try {
            setLoading(true)
            const res = await axios.get(`${BASE_URL}/geo/1.0/direct?q=${city}&limit=5&appid=${apiKey}`)
            if (res?.data?.length > 0) {
                setCoordinates({ lat: res?.data[0]?.lat, lon: res?.data[0]?.lon }); // update  
                setCityName(res?.data[0]?.name)
                setLoading(true)
            }
            else {
                toast.error('City name not Available!', {
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                });
                setCity(data?.name)
            }


        } catch (error) {
            setLoading(true)
            console.log(error)
        }

    }
    const convertCountryCode = (country) => {
        let regionNames = new Intl.DisplayNames(["en"], { type: "region" });
        return regionNames.of(country)
    }

    const getWeatherData = async () => {
        try {
            setLoading(true)
            if (coordinates.lat !== 0) { 
                console.log(coordinates)
                const res = await axios.get(`${BASE_URL}/data/2.5/weather?lat=${coordinates.lat}&lon=${coordinates.lon}&appid=${apiKey}`)
                if (res?.status === 200) {
                    setData(res?.data);
                    setLoading(false)
                }
            }
        } catch (error) {
            setLoading(true)
            console.log(error);

        }
    }

    return (
        <>
            <Toaster />
            {
                loading ?
                    <div className="container">
                        <div className="weather__header">
                            <div className="weather__search">
                                <input type="text" placeholder="Search for a city..." className="weather__searchform" onChange={(e) => setCity(e.target.value)} />
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </div>
                            <div className="weather__units">
                                <Skeleton height={30} width={100} />
                            </div>
                        </div>
                        <div className="weather__body">


                            <h1 className="weather__city">
                                <Skeleton height={30} />

                            </h1>
                            <div className="weather__datetime">
                            <Skeleton height={20} />
                            </div>
                            <div className="weather__forecast">
                                <Skeleton  width={60} />
                            </div>
                            <div className="weather__icon">
                                <Skeleton height={30} />

                            </div>
                            <p className="weather__temperature">
                                <Skeleton height={30} />
                            </p>
                            <div className="weather__minmax">
                                <Skeleton height={30} />
                            </div>
                        </div>

                        <div className="weather__info">
                            <div className="weather__card">
                                <i className="fa-solid fa-temperature-full"></i>
                                <div>
                                    <p>Real Feel</p>
                                    <Skeleton height={20} />
                                </div>
                            </div>
                            <div className="weather__card">
                                <i className="fa-solid fa-droplet"></i>
                                <div>
                                    <p>Humidity</p>
                                    <Skeleton height={20} />
                                </div>
                            </div>
                            <div className="weather__card">
                                <i className="fa-solid fa-wind"></i>
                                <div>
                                    <p>Wind</p>
                                    <Skeleton height={20} />
                                </div>
                            </div>
                            <div className="weather__card">
                                <i className="fa-solid fa-gauge-high"></i>
                                <div>
                                    <p>Pressure</p>
                                    <Skeleton height={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div className="weather__header">
                            <div className="weather__search">
                                <input type="text" placeholder="Search for a city..." className="weather__searchform" onChange={(e) => setCity(e.target.value)} />
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </div>
                            <div className="weather__units">
                                <span className="weather_unit_celsius"> {(data?.main?.temp - 273.15).toFixed(2)}°C</span>
                                <span className="weather_unit_farenheit"> {((data?.main?.temp - 273.15) * 9 / 5 + 32).toFixed(2)}°F</span>
                            </div>
                        </div>
                        <div className="weather__body">


                            <h1 className="weather__city">
                                {cityName}, {data?.sys?.country ? convertCountryCode(data?.sys?.country) : ''}

                            </h1>
                            <div className="weather__datetime">
                                {dateTime}
                            </div>
                            <div className="weather__forecast">
                                {data?.weather ? data.weather[0].main : ''}
                            </div>
                            <div className="weather__icon">
                                <img src={data?.weather ? `https://openweathermap.org/img/wn/${data?.weather[0]?.icon}@2x.png` : ''} />

                            </div>
                            <p className="weather__temperature">
                                {(data?.main?.temp - 273.15).toFixed(2)}°C
                            </p>
                            <div className="weather__minmax">
                                <p>Min:   {(data?.main?.temp_min - 273.15).toFixed(2)}°C</p>
                                <p>Max: {(data?.main?.temp_max - 273.15).toFixed(2)}°C</p>
                            </div>
                        </div>

                        <div className="weather__info">
                            <div className="weather__card">
                                <i className="fa-solid fa-temperature-full"></i>
                                <div>
                                    <p>Real Feel</p>
                                    <p className="weather__realfeel"> {(data?.main?.feels_like - 273.15).toFixed(2)}°C</p>
                                </div>
                            </div>
                            <div className="weather__card">
                                <i className="fa-solid fa-droplet"></i>
                                <div>
                                    <p>Humidity</p>
                                    <p className="weather__humidity">{data?.main?.humidity}%</p>
                                </div>
                            </div>
                            <div className="weather__card">
                                <i className="fa-solid fa-wind"></i>
                                <div>
                                    <p>Wind</p>
                                    <p className="weather__wind">{data?.wind?.speed} meter/sec</p>
                                </div>
                            </div>
                            <div className="weather__card">
                                <i className="fa-solid fa-gauge-high"></i>
                                <div>
                                    <p>Pressure</p>
                                    <p className="weather__pressure">{data?.main?.pressure} hPa</p>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>
    )
}

export default Weather