import React from 'react' 
import Weather from './pages/Weather'
import './App.css'

const App = () => {
  return ( 
    <Weather/>
  )
}

export default App